import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import './ModalConfirmDeletePublication.css'

class ModalConfirmDeletePublication extends React.Component {
  constructor(props) {
      console.log("ModalConfirmDeletePublication.props:", props);
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    const externalCloseBtn = <button className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.toggle}>&times;</button>;
    return (
      <div>
        {((this.props.idhistorique_envois != -1) && (!this.props.date_publication_livret)) && //Deletion forbidden as Booklet has been published
        <DeleteOutline className="CursorPointer" onClick={this.toggle}/>}

        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} external={externalCloseBtn}>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalBody>
            <b>Retirer de la publication</b><br />
            Voulez-vous supprimer cette requête de la publication ?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={()=>{this.props.onConfirm(this.props.idhistorique_envois);this.toggle()}}>Supprimer</Button>
            <Button color="secondary" onClick={this.toggle}>Annuler</Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalConfirmDeletePublication;