/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from 'react';
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MaterialTable from 'material-table';
import MailOutline from '@material-ui/icons/MailOutline'
import {globals} from '../Globals/Globals'

class ModalHistoriqueSuivis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      historique:[], 
      modal: false,
      sujet_revise : this.props.rowData.sujet_revise,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle = () => {
    //Optimization fetchTotalHistorique();

    /*
    this.setState(
      {
        modal:!this.state.modal,
        sujet_revise : this.props.rowData.sujet_revise
      }) */
    
      this.setState(prevState => ({
        modal: !prevState.modal, 
        sujet_revise : this.props.rowData.sujet_revise
      }), ()=>{if (this.state.modal == true) {this.fetchTotalHistorique();} });
  }
  componentDidMount = () => {
    this.materialTableRef = React.createRef();
    this.requetes_service_db_url = globals.parameters.local?'http://localhost:4001'
                            :`https://requetes.jesusestlareponsepourtous.org`;

    this.survey_service_db_url = globals.parameters.local?'http://localhost:4002'
                            :`envois.jesusestlareponsepourtous.org`;
  }

  fetchTotalHistorique = async () => {
    console.log(`this.props.rowData.idrequetes => ${this.props.rowData.idrequetes}`)
    const fetchresult = await axios.get(`${this.requetes_service_db_url}/requete-histo-count?idrequetes=${this.props.rowData.idrequetes}`);
    console.log("fetchresult =>", fetchresult)
    this.setState({historique : fetchresult.data.items});
  };

  suivis_token = (p_int_nb_suivis, p_bool_click_enabled) => {
    return(
      <div>
        <MailOutline className="CursorPointer" onClick={this.toggle}/>
        {p_int_nb_suivis}
      </div>
    )
  }

  suivis_token_OLD = (p_int_nb_suivis, p_bool_click_enabled) => {
    //const color_level = ["white","yellow","orange","purple","green","blue","black","red"];
    const color_level = [
      {backgroundColor:"white", color:"black"},
      {backgroundColor:"yellow", color:"black"},
      {backgroundColor:"orange", color:"blue"},
      {backgroundColor:"purple", color:"white"},
      {backgroundColor:"green", color:"white"},
      {backgroundColor:"blue", color:"white"},
      {backgroundColor:"black", color:"white"},
      {backgroundColor:"red", color:"white"}
    ];
    
    //limit color level
    let current_color_level = color_level[color_level.length - 1].backgroundColor;//Set max color by default
    let current_font_color_level = color_level[color_level.length - 1].color;//Set max color by default

    if (p_int_nb_suivis < color_level.length){
      //Return available color level
      current_color_level = color_level[p_int_nb_suivis].backgroundColor;
      current_font_color_level = color_level[p_int_nb_suivis].color;
    }

    if (p_bool_click_enabled)
    return(
          <span
            style={{ height: 36,
                width: 36,
                borderColor:'blue',
                borderStyle:'solid',
                borderWidth:'3px',
                backgroundColor: current_color_level,
                borderRadius: '50%',
                display:'inline-block',
                cursor:'pointer',
                textAlign:'center',
                color:current_font_color_level,
                padding:'4px'
            }}
          onClick={this.toggle} hint="Envois" title="Envois">{p_int_nb_suivis}</span>
    )
    else
    return(
          <span
            style={{ height: 36,
                width: 36,
                borderColor:'lightgray',
                borderStyle:'solid',
                borderWidth:'4px',
                backgroundColor: current_color_level,
                borderRadius: '50%',
                display:'inline-block',
                cursor:'default',
                textAlign:'center',
                color:current_font_color_level,
                padding:'4px'
            }}>{p_int_nb_suivis}</span>
    )
  }

  render() {
    const externalCloseBtn = <button className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.toggle}>&times;</button>;
    return (
      <div>
        {/*
        <MailOutline className="CursorPointer" onClick={this.toggle}/>*/}
        {this.suivis_token (this.props.rowData.nb_suivis, true)}
         

        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} external={externalCloseBtn}>
          <ModalHeader>Historique de la requête</ModalHeader>
          <ModalBody>
          <div className="form-group row">
                <label className="col-sm-2 col-form-label">Requête:</label>
                <div className="col-sm-10">
                    <textarea name="sujet_revise" className="form-control" value={this.state.sujet_revise} type="text" readonly="true"/>
                </div>
          </div>
          <MaterialTable
                tableRef={this.materialTableRef}
                icons={this.props.tableIcons}
                
                options ={{
                  grouping: false,
                  search: false,
                  showTitle: false,
                  sorting:false
                }}

                columns={[
                  {
                    title: 'Suivis',
                    field: 'nb_suivis',
                    render: rowData => (
                        this.suivis_token(rowData.nb_suivis, false)
                      ),
                  },
                  { title: 'Date', field: 'time_stamp' },
                  { title: 'Etat', field: 'titre_etat_envoi' }
                ]}

                data={query =>
                  new Promise((resolve, reject) => {
                    let url = `${this.requetes_service_db_url}/req-hst-page?idrequetes=${this.props.rowData.idrequetes}`;

                    //-- pagination support
                    url += '&size=' + query.pageSize; //Set number of records by page
                    url += '&offset=' + (query.page) * query.pageSize //Compute first record offset for requested page
                    
                    fetch(url)
                      .then(response => response.json())
                      .then(result => {
                        resolve({
                          data: result.items,
                          page: query.page,//Current page
                          totalCount: this.state.historique.length,
                        })
                      })
                  })
                }
            />

          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={()=>{this.toggle()}}>Ok</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalHistoriqueSuivis;