/*
Retranscriptions component
*/

import React, { Component } from 'react';
import axios from 'axios';
import { forwardRef } from 'react';
import MaterialTable, { MTableToolbar} from 'material-table';
//import styled from 'styled-components';
import ModalHistoriqueEnvois from '../ModalHistoriqueEnvois/ModalHistoriqueEnvois';
import ModalRevisions from '../ModalRevisions/ModalRevisions';
import ModalAffectationLivret from '../ModalAffectationLivret/ModalAffectationLivret';
import ModalConfirmArchivageRequete from '../ModalConfirmArchivageRequete/ModalConfirmArchivageRequete'
import './Retranscriptions.css'

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Rubriques2 from '../Rubriques2/Rubriques2';
import Etats2 from '../Etats2/Etats2';
import BookletsCombo from '../BookletsCombo/BookletsCombo';

import {globals} from '../Globals/Globals'

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

class Retranscriptions extends Component{
    constructor(props){
        super(props);
        this.state = {
            requests:[], 
            selected_subject:null, 
            openPublication:false, 
            openArchivage:false, 
            filter_rubrique_id: -1, 
            filter_etat_id:-1,
            filter_idenvois:-1};

        this.togglePublication = this.togglePublication.bind(this);
        this.materialTableRef = React.createRef();
    }

    componentDidMount = () => {
        this.service_db_url = globals.parameters.local?'http://localhost:4001/requetes-page-filter'
                            :`https://requetes.jesusestlareponsepourtous.org/requetes-page-filter`;
    }

    refreshMaterialTable = () => {
      if (this.materialTableRef.current)
        this.materialTableRef.current.onQueryChange();
      else
        console.error("refreshMaterialTable failed");
    }

    togglePublication = () => {    
        this.setState({openPublication:!this.state.openPublication}, 
            ()=>{ if (!this.state.openPublication) this.refreshMaterialTable();});//refresh table on publication modal close
    }

    toggleArchivage = () => {    
        this.setState({openArchivage:!this.state.openArchivage}, 
            ()=>{ if (!this.state.openArchivage) this.refreshMaterialTable();});//refresh table on publication modal close
    }

    OnChangeRubriqueFilter = (selected_rubrique) => {
      this.setState({
        filter_rubrique_id:selected_rubrique.idrubriques}, 
        ()=>{this.refreshMaterialTable(); console.log("selected rubrique;",selected_rubrique)});
    }

    OnChangeEtatFilter = (selected_etat) => {
      this.setState({
        filter_etat_id:selected_etat.idetats}, 
        ()=>{this.refreshMaterialTable(); console.log("selected etat;",selected_etat)});
    }

    OnChangeBookletFilter = (selected_booklet) => {
      this.setState({
        filter_idenvois:selected_booklet.idenvois}, 
        ()=>{this.refreshMaterialTable(); console.log("selected booklet;",selected_booklet)});
    }

    requestItemRendered = (request) => {
        if (!request)
            return(<span>Choisir une requete ...</span>)
        else
            return(
                <span>                        
                    <span>&nbsp;{request.time_stamp} : </span> 
                    <span>&nbsp;{request.nom},</span> 
                    <span>&nbsp;{request.prenom},</span> 
                    <span>&nbsp;{request.email},</span> 
                    <span>&nbsp;{request.tel},</span> 
                    <span>&nbsp;{request.sujet_requete},</span> 
                    <span>&nbsp;{request.beneficiaire}</span> 
                </span>
            );
    }

    RemoteData = () => {
        return (
          <div>
          <MaterialTable
            tableRef={this.materialTableRef}
            icons={tableIcons}
            title="Retranscriptions."
            actions={[
                rowData => ({
                    icon: () => <ModalAffectationLivret 
                                  modal={this.state.openPublication} 
                                  toggle={this.togglePublication} 
                                  data={this.state.data_selection} 
                                  refreshHandler={this.refreshMaterialTable}/>,
                    tooltip: 'Affectation au livret...',
                    onClick: (evt, data) => {
                                              //alert('You want to PUBLISH ' + data.length + ' rows');
                                              this.setState({data_selection:data},()=>{this.setState({openPublication:true}, ()=>{console.clear(); console.log("new state", this.state);})})
                                            }
                }),
                rowData => ({
                    icon: () => <ModalConfirmArchivageRequete 
                                  modal={this.state.openArchivage} 
                                  toggle={this.toggleArchivage} 
                                  data={this.state.data_selection} 
                                  refreshHandler={this.refreshMaterialTable}/>,
                    tooltip: 'Archiver...',
                    onClick: (evt, data) => {
                                              //alert('You want to PUBLISH ' + data.length + ' rows');
                                              this.setState({data_selection:data},()=>{this.setState({openArchivage:true}, ()=>{console.clear(); console.log("new state", this.state);})})
                                            }
                })

              ]}
            options={{
                selection: true,
                search: true,
                /*grouping: true, 2DO later*/
                grouping: false,
                showTitle: false,
                actionsColumnIndex: -1,
                //pageSize: rowsPerPage,
                //pageSizeOptions: [5, 10, 20, { value: data?data.length:100, label: 'All' }],
                pageSizeOptions: [5, 10, 20, 40, 200],
              }}
              
            columns={[
              {
                title: 'Envois',
                field: 'nb_envois',
                onClick: () => {alert("nb_envois cliqué")},
                render: rowData => (
                    <ModalHistoriqueEnvois rowData={rowData} tableIcons={tableIcons} refreshHandler={this.refreshMaterialTable}/>
                  ),
              },
              { title: 'Date', field: 'time_stamp_fr' },
              { title: 'Pays', field: 'titre_pays' },
              /* Disabled for the moment to gain places on array
              { 
                title: 'Active', 
                field: 'enabled',
                render: rowData => (
                  <span>{rowData.enabled == 1?"Oui":"Non"}</span>
                ) 
              },*/
              { title: 'Rubrique', field: 'titre_rubrique' },
              { 
                title: 'Chaîne de prière', 
                field: 'chaine_de_priere',
                render: rowData => (
                  <span>{rowData.chaine_de_priere == 1?"Oui":"Non"}</span>
                ) 
              },
              { title: 'Etat', field: 'titre_etat' },
              { 
                  title: 'Demandeur', 
                  field: 'prenom_demandeur', 
              },
              { title: 'Bénéficiaire', field: 'beneficiaire' },

              {
                title: 'Requête',
                field: 'sujet_revise',
                render: rowData => (
                    <ModalRevisions rowData={rowData} idrequetes={rowData.idrequetes} refreshHandler={this.refreshMaterialTable}/>
                  ),
              },
            ]}

            data={query =>
              new Promise((resolve, reject) => {
                let url = `${this.service_db_url}`;

                //-- pagination support
                url += '?size=' + query.pageSize; //Set number of records by page
                url += '&offset=' + (query.page) * query.pageSize //Compute first record offset for requested page
                
                if ((query.orderBy) && (query.orderBy != "undefined") && (query.orderBy.field)){
                  url += `&order_by_clause= order by ${query.orderBy.field.replace("time_stamp_fr","time_stamp")} ${query.orderDirection}`//Order by management
                }
                else{
                  url += "&order_by_clause="
                }
                
                if (query.search.trim().length > 0){
                  url += `&where_clause= where ((INSTR(beneficiaire,'${query.search.trim()}') > 0) || (INSTR(sujet_requete,'${query.search.trim()}') > 0) || (INSTR(sujet_revise,'${query.search.trim()}') > 0))`
                }
                else{
                  url += `&where_clause= where true`;
                }

                if (this.state.filter_rubrique_id != -1){
                  url += ` and (idrubriques = ${this.state.filter_rubrique_id})`
                }

                if (this.state.filter_etat_id != -1){
                  url += ` and (idetats = ${this.state.filter_etat_id})`
                }

                if (this.state.filter_idenvois != -1){
                  url += ` and (he.idenvois = ${this.state.filter_idenvois})`
                }

                console.log("filter_url:",url)

                fetch(url)
                  .then(response => response.json())
                  .then(result => {
                    resolve({
                      data: result.items,
                      page: query.page,//Current page
                      totalCount: result.items.length>0?result.items[0].total_count:0,
                    })
                  })
              })
            }
            
            components={{
              Toolbar: props => (
                <div class="row">
                  <MTableToolbar {...props} />
                  <div class="md-4" style={{padding: '0px 10px', textalign:'right'}}>
                    <span>Filtrer par rubriques:</span>
                    <Rubriques2 
                        rowData={{idrubriques:this.state.filter_rubrique_id}} 
                        defaultSelection={{"idrubriques":"-1","titre_rubrique":"Toutes"}}
                        OnClickRubrique_callback = {this.OnChangeRubriqueFilter}/>
                  </div>

                  <div class="md-4" style={{padding: '0px 10px', textalign:'right'}}>
                    <span>Filtrer par etats:</span>
                    <Etats2 
                        rowData={{idetats:this.state.filter_etat_id}} 
                        defaultSelection={{"idetats":"-1","titre_etat":"Tous"}}
                        OnClickEtat_callback = {this.OnChangeEtatFilter}/>
                  </div>

                  <div class="md-4" style={{padding: '0px 10px', textalign:'right'}}>
                    <span>Filtrer par livret:</span>
                    <BookletsCombo 
                        rowData={{idenvois:this.state.filter_idenvois}} 
                        defaultSelection={{"idenvois":"-1","titre_envoi":"Tous livrets"}}
                        OnClickBooklet_callback = {this.OnChangeBookletFilter}/>
                  </div>
                </div>
              ),}}
          />
          </div>
        )
      }
      
    render(){
            return (<div>{this.RemoteData()}</div>);
    }
}

export default Retranscriptions;