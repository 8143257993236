/*
FileForm Component
*/
import React, { Component } from 'react';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import Blob3 from '../Blob3/Blob3';
import { globals } from '../Globals/Globals'
import Editor from '../Editor/Editor';
import Multiselect from 'multiselect-react-dropdown';
import "../../App.css"

class FileForm extends Component {
    constructor(props) {
        super(props);

        this.clearObject = {
            availableCategories: ["Fascicule", "Priere", "chaine de prière", "santé", "autre"],
            selectedCategories: [],

            did_not_change: true,
            file_data: "",
            full_file_name: "",
            time_stamp: "",
            categories: "",
            description: "",
            title: "",
            is_enabled: 0,
            formErrors: {
                file_data: '', full_file_name: '',
                time_stamp: '',
                description: '', title: '',
            }
        }

        this.state = this.clearObject;
        this.enabledCheckBoxRef = React.createRef();
    }

    clearForm = () => {
        let clearObject2 = Object.assign({}, this.clearObject, this.props.data)
        this.setState(clearObject2);
    }

    onFormSubmitHandler = async event => {
        event.preventDefault();

        if (!this.onCheckFormValidationBeforeSubmit()) return;//Cancel submitt

        if (globals.parameters.debugging) {
            //Call micro-service
            console.clear();
            console.log("state size", Math.floor(JSON.stringify(this.state).length), "Mo state =>", this.state);
        }

        var serviceResult = null
        try {
            await fetch(
                `${globals.file_add_endpoint.url}`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(this.state)
            }).then(
                results => { return results.text(); }
            ).then(
                (server_response) => {
                    console.log("sever_response =>", server_response)
                    serviceResult = JSON.parse(server_response);
                    //------------------------------------------ Asynchronous promise result handle
                    //Get service aknowledge before resetting state values.
                    console.log("client_add_requetes_log => returns : ", serviceResult);

                    if (serviceResult.state === true) {
                        //
                        alert("Média ajouté !")

                        //Clear form
                        if (!globals.parameters.debugging)
                            this.clearForm();

                        //Trigger on terminate
                        if (this.props.onTerminate) {
                            this.props.onTerminate()
                        }
                    }
                    else {
                        alert("Erreur:" + serviceResult.message);
                    }
                    //--------------------------------------- End of promise Accept
                }
            )
        } catch (err) {
            alert(`Verifiez votre connexion wifi : ${err}`);
            return;
        }
    }

    onFormUpdateHandler_NEXT = async event => {
        event.preventDefault();

        //Call micro-service
        if (globals.parameters.debugging) {
            console.clear()
            console.log("state size", Math.floor(JSON.stringify(this.state).length), "Mo state =>", this.state);
        }

        var serviceResult = null
        try {

            const res = await fetch(
                `${globals.file_update_endpoint.url}`, {
                    
                headers: {
                    'Content-Type': 'application/json'
                },

                method: "PUT",
                body: JSON.stringify(this.state)
            });
            
            if (res.ok) {
                console.log("res.text() ====> ",res.text())
            }
            else {
                throw await res.text();
            }
        } catch (err) {
            alert(`Erreur de connexion au serveur : ${err}`);
            return;
        }
    }

    onFormUpdateHandler = async event => {
        event.preventDefault();

        //Call micro-service
        if (globals.parameters.debugging) {
            console.clear()
            console.log("state size", Math.floor(JSON.stringify(this.state).length), "Mo state =>", this.state);
        }

        var serviceResult = null
        try {

            await fetch(
                `${globals.file_update_endpoint.url}`, {
                headers: {
                    'Content-Type': 'application/json'
                },

                method: "PUT",
                body: JSON.stringify(this.state)
            }).then(
                results => { return results.text(); }
            ).then(
                (server_response) => {
                    if (globals.parameters.debugging)
                        console.log("sever_response =>", server_response)

                    serviceResult = JSON.parse(server_response);
                    //------------------------------------------ Asynchronous promise result handle
                    //Get service aknowledge before resetting state values.

                    if (serviceResult.state === true) {
                        //
                        alert("Média modifié !")

                        //Clear form
                        if (!globals.parameters.debugging)
                            this.clearForm();

                        //Return to table mode
                        //Trigger on terminate
                        if (this.props.onTerminate) {
                            this.props.onTerminate()
                        }
                    }
                    else {
                        alert("Erreur:" + serviceResult.message);
                    }
                    //--------------------------------------- End of promise Accept
                }
            )
        } catch (err) {
            alert(`Erreur de connexion avec le serveur : ${err}`);
            return;
        }
    }

    componentDidMount = () => {
        this.handle_picture = this.handle_picture.bind(this);
        this.handle_pdf = this.handle_pdf.bind(this);
        this.handle_full_file_name = this.handle_full_file_name.bind(this);
        this.handle_title = this.handle_title.bind(this);
        this.handle_time_stamp = this.handle_time_stamp.bind(this);
        this.handle_description_rich_edit = this.handle_description_rich_edit.bind(this);
        //Component initialization in edit mode
        if (this.props.data) {
            this.clearForm();
        }
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.data !== prevProps.data) {
            //Lookup field props has changed
            this.clearForm()
        }
    }

    handle_full_file_name = (e) => {
        e.preventDefault();
        //Update full_file_name value from form field
        this.setState({ did_not_change: false, full_file_name: e.target.value }, () => { this.checkValidation(e.target.name); });
    }

    handle_time_stamp = (e) => {
        e.preventDefault();
        //Update time_stamp value from form field
        this.setState({ did_not_change: false, time_stamp: e.target.value }, () => { this.checkValidation(e.target.name); });
    }

    handle_title = (e) => {
        e.preventDefault();
        //Update title value from form field
        this.setState({ did_not_change: false, title: e.target.value }, () => { this.checkValidation(e.target.name); });
    }

    handle_description_rich_edit = (new_rich_edit) => {
        //Update description value from form field
        this.setState({ did_not_change: false, description: new_rich_edit }, () => { this.checkValidation("description"); });
    }

    onChangeCheckboxEnabled = (e) => {
        this.setState({ is_enabled: e.target.checked ? 1 : 0 });
    }

    onClickCheckboxEnabled = (e) => {
        e.preventDefault()
        this.setState(
            { is_enabled: !this.enabledCheckBoxRef.current.checked ? 1 : 0 });
    }

    //Blob methods
    handle_picture = (UploadFile, UploadFileBase64) => {
        this.setState({
            did_not_change: false,
            file_data: UploadFileBase64.data,
            full_file_name: UploadFile ? UploadFile.name : "",
        },
            () => { this.checkValidation("file_data") });//2DO Debug CORS raising error
    }

    handle_pdf = (UploadFile, UploadFileBase64) => {
        console.log("UploadFile:", JSON.stringify(UploadFile))
        this.setState({
            did_not_change: false,
            file_data: UploadFileBase64,
            full_file_name: UploadFile ? UploadFile.name : ""
        }, () => { this.checkValidation("file_data") }, console.log("state with pdf=>", this.state));//2DO Debug CORS raising error
    }

    //Validation methods
    errorClass = (error) => {
        return (error.length === 0 ? '' : 'has-error');
    }

    checkValidation = (fieldName) => {
        let value = null;
        try {
            value = this.state[fieldName];
        }
        catch (e) {
            alert(`Field '${fieldName}' not found`)
        }

        if (globals.parameters.debugging)
            console.log("fieldName:", fieldName, " value:", value)

        let fieldValidationErrors = this.state.formErrors;

        switch (fieldName) {
            case 'file_data':
                fieldValidationErrors.file_data = (value.length > 0) ? '' : ' est obligatoire';
                break;

            case 'full_file_name':
                fieldValidationErrors.full_file_name = (value.length > 1) ? '' : ' est obligatoire';
                break;

            case 'title':
                fieldValidationErrors.title = (value.length > 1) ? '' : ' est obligatoire';
                break;

            case 'time_stamp':
                fieldValidationErrors.time_stamp = (value.length > 0) ? '' : ' est obligatoire';
                break;

            case 'description':
                fieldValidationErrors.description = (value.length > "<p><br></p>".length) ? '' : ' est trop court';
                break;

            default:
                alert(`checkValidation field' ${fieldName}' not found !`)
                break;
        }

        if (globals.parameters.debugging) {
            console.clear()
            console.log(`-----------------`)
            console.log(`fieldName => ${fieldName}`)
            console.log(`value => ${value}`)
            console.log(`fieldValidationErrors => ${fieldValidationErrors}`)
            console.log(`fieldValidationErrors.join('').length => ${Object.values(fieldValidationErrors).join("").length}`)
        }

        //update internal state
        this.setState({ formErrors: fieldValidationErrors }, () => { if (globals.parameters.debugging) console.log("formErrors =", this.state.formErrors) });

        return (Object.values(fieldValidationErrors).join("").length === 0);
    }

    onCheckFormValidationBeforeSubmit = () => {
        //Force all fields check
        try {
            let canSubmit = this.checkValidation('file_data')
            canSubmit &= this.checkValidation('title')
            canSubmit &= this.checkValidation('full_file_name')
            canSubmit &= this.checkValidation('time_stamp')
            canSubmit &= this.checkValidation('description')

            return (canSubmit);
        }
        catch (e) {
            return false;
        }
    }

    render = () => {
        return (
            <div id="media-form-container">
                <ModalBody>
                    <form id="media-form" onSubmit={this.props.data ? this.onFormUpdateHandler : this.onFormSubmitHandler}>
                        <h5>Ajouter/publier un média</h5>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label className="col-md-12 col-form-label">Nom du fichier d'origine:</label>
                                <div className="col-md-10">
                                    <input
                                        readOnly="true"
                                        name="full_file_name"
                                        className="form-control"
                                        value={this.state.full_file_name}
                                        onChange={(e) => { this.handle_full_file_name(e); }}
                                        type="text" placeholder="Nom du média avec extension ie:fasciule.pdf" />

                                    {/* Sticky error label */}
                                    <label
                                        className="form-error">
                                        {this.state.formErrors.full_file_name}
                                    </label>
                                </div>

                            </div>

                            <div className="col-md-6">
                                <label className="col-md-12 col-form-label">Données du média:</label>
                                <div className="col-md-10">
                                    <Blob3
                                        class="pic"
                                        Caption="Charger le média ..."
                                        data={this.state.file_data}
                                        uploadPicture={this.handle_picture}
                                        uploadPdfFile={this.handle_pdf}
                                        buttonStyle={"btn btn-secondary"}
                                    />

                                    {/* Sticky error label */}
                                    <label
                                        className="form-error">
                                        {this.state.formErrors.file_data}
                                    </label>
                                </div>
                            </div>
                        </div>


                        <div className="form-group row">
                            <div className="col-md-6">
                                <label className="col-md-12 col-form-label">Date:</label>
                                <div className="col-md-10">
                                    <input name="time_stamp" className="form-control" value={this.state.time_stamp} onChange={(e) => { this.handle_time_stamp(e); }} type="date" placeholder="Date de l'évènement" />

                                    {/* Sticky error label */}
                                    <label
                                        className="form-error">
                                        {this.state.formErrors.time_stamp}
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label className="col-md-12 col-form-label">Titre:</label>
                                <div className="col-md-10">
                                    <input name="title" className="form-control" value={this.state.title} onChange={(e) => { this.handle_title(e); }} type="text" placeholder="Titre de l'évènement" />

                                    {/* Sticky error label */}
                                    <label
                                        className="form-error">
                                        {this.state.formErrors.title}
                                    </label>
                                </div>
                            </div>
                        </div>


                        <div className="form-group row">
                            <div className="col-md-6">
                                <label className="col-md-12 col-form-label">Catégories affectées à ce média :</label>
                                <div className="col-md-12">
                                    <Multiselect
                                        showArrow
                                        options={this.state.availableCategories}
                                        isObject={false}
                                        displayValue="key"
                                        selectedValues={this.state.selectedCategories}
                                        placeholder="Ajouter une catégorie"
                                        emptyRecordMsg=""
                                        onSelect={(selectedList, selectedItem) => { this.setState({ categories: selectedList.join(";") }); }}
                                        onRemove={(selectedList, selectedItem) => { this.setState({ categories: selectedList.join(";") }); }}
                                        disable={false}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <label className="col-md-12 col-form-label">Média activé:</label>
                                <div className="col-md-12">
                                    <input
                                        className='pointer'
                                        ref={this.enabledCheckBoxRef}
                                        id="is_enabled"
                                        type="checkbox"
                                        checked={parseInt(this.state.is_enabled) === 1 ? true : false}
                                        onChange={(e) => { this.onChangeCheckboxEnabled(e) }} />

                                    <label className='pointer' onClick={(e) => { this.onClickCheckboxEnabled(e); }}>Media activé</label>
                                </div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-12">
                                <label className="col-md-12 col-form-label">Description de l'évènement:</label>
                                <div className="col-md-12 editor-container">

                                    {/* Sticky error label */}
                                    <label
                                        className="form-error">
                                        {this.state.formErrors.description}
                                    </label>
                                    <Editor
                                        value={this.state.description}
                                        onChange={(value) => { this.handle_description_rich_edit(value) }}
                                        readOnly={false}
                                        style={{ height: "150px", margin: "0em", flex: "1" }}
                                    />
                                </div>
                            </div>
                        </div>

                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        form="media-form"
                    >{this.props.data ? "Enregistrer les modifications" : "Soumettre le média"}</Button>

                    <Button
                        color="secondary"
                        onClick={(e) => { this.clearForm(); }}
                        hidden={this.state.did_not_change}>{this.props.data ? "Annuler les modifications" : "Tout effacer"}
                    </Button>

                    {this.props.onClose &&
                        <Button
                            color="secondary"
                            hidden={!this.state.did_not_change}
                            onClick={(e) => { this.props.onClose(); }}>Fermer</Button>}
                </ModalFooter>
            </div>
        )
    }
}

export default FileForm;