import logo from './logo.svg';
import './App.css';
import React, { Component } from 'react';
import {Button} from 'reactstrap';
import Retranscriptions from './Components/Retranscriptions/Retranscriptions';
import SideMenu from './Components/SideMenu/SideMenu';
import StatsTable from './Components/StatsTable/StatsTable';
import BookletsPage from './Components/BookletsPage/BookletsPage'
import ThanksPage from './Components/ThanksPage/ThanksPage';
import Suivis from './Components/Suivis/Suivis'
import Archives from './Components/Archives/Archives'
import AdminAuthent from './Components/AdminAuthent/AdminAuthent'
import Cookies from 'universal-cookie';
import Blob3 from './Components/Blob3/Blob3'
import FilesRawTable from './Components/FilesRawTable/FilesRawTable'
import FileFormDataSync from './Components/FileFormDataSync/FileFormDataSync'
import FilesRawTableDataSync from './Components/FilesRawTableDataSync/FilesRawTableDataSync'

import { Document, Page,pdfjs } from 'react-pdf';
import FileForm from './Components/FileForm/FileForm';
import axios from 'axios';
import {globals} from './Components/Globals/Globals'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const cookies = new Cookies();
const menu = {Home:0, Stats:1, BookletsPage:2, Suivis:3, Archives:4, Disconnect:5, ThanksPage:6, Media:7};
const _seconds = 1;
const _minutes = 60 * _seconds;
const _cookie_name = "jlrpt_authent"

class App extends Component { 
  constructor(props){
    super(props);
    
    if (globals.parameters.debugging){
      this.setCookies(JSON.stringify({authentified:1, media_enabled:1}));
      this.state = {menuState:menu.Media, authentified:1, pageNumber:1, current_media:null, open_media_form:false}/*Debugged page*/
    }
    else{
      this.state = {menuState:menu.Stats, authentified:0, current_media:null, open_media_form:false}/*Default page*/
    }
  }

  componentDidMount = () => {
    //Enforce cookies reading as page has been refreshed
    this.getCookies();
  }

  setCookies = (cookie_value) => {
    console.log("stCookies=>", cookie_value)
    cookies.set(_cookie_name, cookie_value, { path: '/', maxAge : 30 * _minutes});
  } 

  removeCookies = () => {
    cookies.set(_cookie_name);
  } 

  getCookies_OLD = () => {
    let cookie_value = cookies.get(_cookie_name);
    const cookie_as_num = (cookie_value !== 'undefined' && cookie_value != null)?parseInt(cookie_value,10):0;
    this.setState({authentified : cookie_as_num});
    return cookie_as_num;
  }

  getCookies = () => {
    let cookie_stringified_value = cookies.get(_cookie_name);
    console.log("cookie_stringified_value:", cookie_stringified_value);
    const cookie_object_value = 
          (cookie_stringified_value !== 'undefined' && cookie_stringified_value != null)
            ?cookie_stringified_value:{authentified:0, media_enabled:0};

    this.setState(cookie_object_value, ()=>{console.log("cookie_object_value => ", this.state)});
  }

  onMenuChange = selectedMenu => {
    this.setState({menuState:menu[selectedMenu]});
  }

  onDisconnect = () =>{
    this.setState({authentified:0})
    //Remove cookie
    this.removeCookies();
  }

  onConnect = (check_operator_code_bundle) => {
    //Set cookie with is_published right
    this.setCookies(JSON.stringify({authentified:1, media_enabled:check_operator_code_bundle.is_publisher}));
    //Set State
    this.setState({authentified:1, media_enabled:check_operator_code_bundle.is_publisher});
  }

  handle_picture = (UploadFile, UploadFileBase64) => {
    this.setState(
        {did_not_change:false, 
          picture : UploadFileBase64.data},
           ()=>{
              console.log("handle_picture::this.state:", this.state)
            });//2DO Debug CORS raising error
  }

  handle_pdf = (UploadFile, UploadFileBase64) => {
    this.setState(
        {did_not_change:false, 
          pdf : UploadFileBase64},
           ()=>{
              console.log("handle_pdf::this.state:", this.state)
            });//2DO Debug CORS raising error
  }
  
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({numPages:numPages});
  }

  loadMedia = async (selected_idfile) => {
    //Let us fetch media from remote database
    try{
        const fetchFileData = await axios.get(`${globals.file_load_endpoint.url}?idfile=${selected_idfile}`);
        console.log("fetchFileData:",fetchFileData)
        if (fetchFileData.data.state === true)
            return fetchFileData.data.items[0];//return Media data
    }
    catch(e){
        alert("Vérifiez votre connexion Internet");
        console.clear()
        console.error("erreur:",e)
    }

    return null
}

  /**
     * Triggered as media is turned into edition from FilesRawTable component 
     */
   onMediaEditFromRawTable = async (selected_idfile) => {
    let media_data = null

    if (globals.parameters.debugging){
      console.clear()
      console.log("onMediaEditFromRawTable :: selected_idfile => ", selected_idfile)  
    }

    if (media_data = await this.loadMedia(selected_idfile)){

        //Override categories preselection
        try{
            media_data.selectedCategories = media_data.categories.split(";");//Prière, fascicule and so on..
            media_data.time_stamp = media_data.time_stamp.split(" ")[0];//Remove time part from date time 2022-04-07 hh:mm:ss
        }
        catch(e){
            console.log("categories reset with error e =>", e.message);
            media_data.selectedCategories = [];
        }

        //Set loaded Media data
        this.setState({current_media:media_data, open_media_form:true})
    }
    else{
        alert("Vérifiez votre connexion Internet");
    }
  }

  /**
     * Triggered as media is turned into edition from RawTableDataSync component 
     */
   onMediaEditFromRawTableDataSync = async (selected_idfile, selected_data) => {
    let media_data = selected_data

    if (globals.parameters.debugging){
      console.clear()
      console.log("onMediaEditFromRawTable :: selected_idfile => ", selected_idfile)  
      console.log("onMediaEditFromRawTable :: selected_data => ", selected_data)  
    }

    if (media_data){
        //Override categories preselection
        try{
            media_data.selectedCategories = media_data.categories.split(";");//Prière, fascicule and so on..
            media_data.time_stamp = media_data.time_stamp.split(" ")[0];//Remove time part from date time 2022-04-07 hh:mm:ss
        }
        catch(e){
            console.log("categories reset with error e =>", e.message);
            media_data.selectedCategories = [];
        }

        //Set loaded Media data
        this.setState({current_media:media_data, open_media_form:true})
    }
    else{
        alert("Vérifiez votre connexion Internet");
    }
  }

  /**
     * Triggered as media is turned into edition from FilesRawTable component 
     */
   onOpenMedia = async (selected_file_object) => {
    console.clear()
    console.log("onOpenMedia :: selected_file_object => ", selected_file_object)
  }

  render = () =>{
    return (
      <div className="App">
        {this.state.authentified == 2 && 
        <div>
        <h1 style= {{color:"blue"}}>Déploiement en cours...</h1>
        <h3>L'application sera à nouveau disponible d'ici midi aujourd'hui 10 septembre 2021</h3></div>}

        {this.state.authentified == 0 && 
        <AdminAuthent onOperatorGranted={(check_operator_code_bundle)=>{this.onConnect(check_operator_code_bundle)}}/>}

        {this.state.authentified == 1 &&
        <div>
          <SideMenu onMenuChange = {this.onMenuChange} onDisconnect={this.onDisconnect}/>
          <div>
            {(this.state.menuState == menu.Home) && 
              <div>
                {/*<h1 className="App-header-disabled">Jésus est la réponse pour tous</h1>*/}
                <h4>Console de retranscription des requêtes</h4>
                <Retranscriptions mode="remote"/>
              </div>
            }

            {(this.state.menuState == menu.Media) && 
              <div className='media-container'>
                {/** next CORS 
                {this.state.open_media_form && <>
                  <h4>Ajout / Modification d'un média</h4>
                  <FileForm
                    data={this.state.current_media}
                    onTerminate={()=>{this.setState({current_media:null, open_media_form:false})}}//Return to table mode
                    onClose={()=>{this.setState({current_media:null, open_media_form:false})}}//Return to table mode
                    />
                </>}*/}
                {/** Using FileFormDataSync */}
                {(parseInt(this.state.media_enabled) === 1 && this.state.open_media_form) && <>
                  <h4>Ajout / Modification d'un média</h4>
                  <FileFormDataSync
                    data={this.state.current_media}
                    onTerminate={()=>{this.setState({current_media:null, open_media_form:false})}}//Return to table mode
                    onClose={()=>{this.setState({current_media:null, open_media_form:false})}}//Return to table mode
                    />
                </>}
                {/**
                {!this.state.open_media_form && <>
                  <h4>Médias disponibles</h4>
                  <div className='top-media-toolbar'>
                    <Button color="primary" onClick={()=>{this.setState({open_media_form:true})}}>Nouveau média</Button>
                  </div>
                  <FilesRawTable
                    onEditMedia={this.onMediaEditFromRawTable}
                    />
                </>}
                 */}

                {parseInt(this.state.media_enabled) === 0 &&  <h1>Droits insuffisants pour configurer les médias</h1>}

                {/** Using FilesRawTableDataSync */}
                {(parseInt(this.state.media_enabled) === 1 && !this.state.open_media_form) && <>
                  <h4>Médias disponibles - DataSync</h4>
                  <div className='top-media-toolbar'>
                    <Button color="primary" onClick={()=>{this.setState({open_media_form:true})}}>Nouveau média</Button>
                  </div>
                  <FilesRawTableDataSync
                    onEditMedia={this.onMediaEditFromRawTableDataSync}
                    table ={true}
                    />
                </>}

                {false && <>
                  <h2>Dynamic boolet</h2>
                  <Blob3
                    Caption="Sélectionnez un PDF"
                    class="pic"
                    data={this.state.picture}
                    uploadPicture={this.handle_picture}
                    uploadPdfFile={this.handle_pdf}
                    />

                  <Document file={this.state.pdf} onLoadSuccess={this.onDocumentLoadSuccess}>
                    <Page pageNumber={this.state.pageNumber} />
                  </Document>
                  <button onClick={()=>{this.setState({pageNumber:this.state.pageNumber - 1 })}}>Precédente</button> 
                  <button onClick={()=>{this.setState({pageNumber:this.state.pageNumber + 1 })}}>Suivante</button> 

                  <p>
                    Page {this.state.pageNumber} of {this.state.numPages}
                  </p>
                </>}
              </div>
            }

            {(this.state.menuState == menu.Stats) && 
              <div>
                <h4>Tableau de bord des requêtes</h4>
                <StatsTable/>
              </div>
            }
            
            {(this.state.menuState == menu.BookletsPage) && 
              <div>
                <h4>Livrets</h4>
                <BookletsPage/>
              </div>
            }
            
            {(this.state.menuState == menu.Suivis) && 
              <div>
                <h4>Campagnes de Suivis de requêtes</h4>
                <Suivis/>
              </div>
            }

            {(this.state.menuState == menu.ThanksPage) && 
              <div>
                <h4>Remerciements</h4>
                <ThanksPage/>
              </div>
            }
            
            {(this.state.menuState == menu.Archives) && 
              <div>
                <h4>Requêtes archivées</h4>
                <Archives/>
              </div>
            }
          </div>
        </div>}
      </div>
    );
  }
}

export default App;
