/*
FileFormDataSync Component
*/
import React, { Component } from 'react';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import Blob3 from '../Blob3/Blob3';
import { globals, cGUID, cTimeStamp } from '../Globals/Globals'
import Editor from '../Editor/Editor';
import Multiselect from 'multiselect-react-dropdown';
import "../../App.css"

class FileFormDataSync extends Component {
    constructor(props) {
        super(props);

        this.clearObject = {
            availableCategories: ["Fascicule", "Priere", "chaine de prière", "santé", "autre"],
            selectedCategories: [],

            did_not_change: true,
            file_data: "",
            full_file_name: "",
            time_stamp: "",
            categories: "",
            description: "",
            title: "",
            is_enabled: 0,
            formErrors: {
                file_data: '', full_file_name: '',
                time_stamp: '',
                description: '', title: '',
            }
        }

        this.state = this.clearObject;
        this.enabledCheckBoxRef = React.createRef();
    }

    clearForm = () => {
        if (globals.parameters.debugging){
            console.log("this.props.data =>", this.props.data)
        }
        let props_data_object = 
        {
            //selectedCategories: this.props.data.data_blob.selectedCategories,
            file_data: this.props.data.data_blob.file_data,
            full_file_name: this.props.data.data_blob.full_file_name,
            time_stamp: this.props.data.data_blob.time_stamp,
            categories:this.props.data.data_blob.categories,
            description:this.props.data.data_blob.description,
            title:this.props.data.data_blob.title,
            is_enabled:this.props.data.data_blob.is_enabled
        }

        //let clearObject2 = Object.assign({}, this.clearObject, this.props.data)
        let clearObject2 = Object.assign({}, this.clearObject, props_data_object)
        this.setState(clearObject2);
    }


    //------------------ POST a new file -----------------------------------
    pushMedia = async p_data_guid => {

        if (!this.onCheckFormValidationBeforeSubmit()) return;//Cancel submitt

        if (globals.parameters.debugging) {
            //Call micro-service
            console.clear();
            console.log("state size", Math.floor(JSON.stringify(this.state).length), "Mo state =>", this.state);
        }

        //Post object using DataSyncLayer
        //Copy datasync compliant object data from state structure
        let newMediaObject = {
            data_guid: p_data_guid?p_data_guid:cGUID(), 
            //createstamp:cTimeStamp(), 
            /*
            company_guid: globals.datasync.jlrpt_company_guid, 
            table_guid: globals.datasync.media_table_guid, */
            data_blob:{
                //selectedCategories: this.state.selectedCategories,
                full_file_name: this.state.full_file_name,
                time_stamp: this.state.time_stamp,
                categories:this.state.categories,
                description:this.state.description,
                title:this.state.title,
                is_enabled:this.state.is_enabled,
                file_data: this.state.file_data
            }}

        if (p_data_guid){
            newMediaObject.updatestamp = cTimeStamp()
        }
        else{
            newMediaObject.createstamp = cTimeStamp()
        }

        let l_o_datasync = Object.assign({}, newMediaObject); 

        if (globals.parameters.debugging){
            console.log("l_o_datasync to be stored => ", l_o_datasync)
        }

        //Set datasync container sytem properties values
        //Dont set updatestamp field - l_o_datasync.updatestamp = cTimeStamp(); //Set time stamps

        //Set user custom data_blob properties values
        l_o_datasync.data_blob.modified = newMediaObject?1:0; //Reset modified flag
        //and so on...

        //Stringify data_blob primary
        l_o_datasync.data_blob = JSON.stringify(l_o_datasync.data_blob).replace(/"/g, '\\"').replace(/'/g, "''").replace(/\\n/g, '\\\\n');

        //Stringify whole datasync object one more time
        let l_s_sync_object_stringified = JSON.stringify(l_o_datasync);

        //Prepare datasync syncPush POST request
        const POSTform = new FormData();

        POSTform.append("action", "syncPush"); //
        POSTform.append("company_guid", globals.datasync.jlrpt_company_guid);
        POSTform.append("table_guid", globals.datasync.media_table_guid);
        POSTform.append("data_sync", l_s_sync_object_stringified);

        //SyncPush Data
        fetch(`${globals.datasync.syncPushUrl}`,{method: "POST", body: POSTform})
        .then(results => {return results.text();})
        .then(
            sync_server_response => {
                let json_response = JSON.parse(sync_server_response);
                if (globals.parameters.debugging)
                    console.log("json_response ->", json_response)
                if (json_response.sync_push_result.state == true){
                    alert(p_data_guid?"Média modifié":"Média ajouté");
                    
                    //Trigger on terminate handler
                    if (this.props.onTerminate) {
                        this.props.onTerminate()
                    }
                }
                else{
                    alert(`Erreur de sauvegarde : " ${json_response.sync_push_result.message}"`);
                }
            }).catch(err => {alert(`Erreur de sauvegarde ${err}`)})
    }

    onFormSubmitHandler = async event => {
        event.preventDefault();
        if (globals.parameters.debugging){
            alert("onFormSubmitHandler")
        }
        
        this.pushMedia(null)
    }


    onFormUpdateHandler = async event => {
        event.preventDefault();
        if (globals.parameters.debugging){
            alert("onFormUpdateHandler")
        }

        this.pushMedia(this.props.data.data_guid)
    }

    componentDidMount = () => {
        this.handle_picture = this.handle_picture.bind(this);
        this.handle_pdf = this.handle_pdf.bind(this);
        this.handle_full_file_name = this.handle_full_file_name.bind(this);
        this.handle_title = this.handle_title.bind(this);
        this.handle_time_stamp = this.handle_time_stamp.bind(this);
        this.handle_description_rich_edit = this.handle_description_rich_edit.bind(this);
        //Component initialization in edit mode
        if (this.props.data) {
            this.clearForm();
        }
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.data !== prevProps.data) {
            //Lookup field props has changed
            this.clearForm()
        }
    }

    handle_full_file_name = (e) => {
        e.preventDefault();
        //Update full_file_name value from form field
        this.setState({ did_not_change: false, full_file_name: e.target.value }, () => { this.checkValidation(e.target.name); });
    }

    handle_time_stamp = (e) => {
        e.preventDefault();
        //Update time_stamp value from form field
        this.setState({ did_not_change: false, time_stamp: e.target.value }, () => { this.checkValidation(e.target.name); });
    }

    handle_title = (e) => {
        e.preventDefault();
        //Update title value from form field
        this.setState({ did_not_change: false, title: e.target.value }, () => { this.checkValidation(e.target.name); });
    }

    handle_description_rich_edit = (new_rich_edit) => {
        //Update description value from form field
        this.setState({ did_not_change: false, description: new_rich_edit }, () => { this.checkValidation("description"); });
    }

    onChangeCheckboxEnabled = (e) => {
        this.setState({ is_enabled: e.target.checked ? 1 : 0 });
    }

    onClickCheckboxEnabled = (e) => {
        e.preventDefault()
        this.setState(
            { is_enabled: !this.enabledCheckBoxRef.current.checked ? 1 : 0 });
    }

    //Blob methods
    handle_picture = (UploadFile, UploadFileBase64) => {
        this.setState({
            did_not_change: false,
            file_data: UploadFileBase64.data,
            full_file_name: UploadFile ? UploadFile.name : "",
            title: (this.state.title =="" && UploadFile) ? UploadFile.name : this.state.title,
            description: (this.state.description =="" && UploadFile) ? UploadFile.name : this.state.description
        },
            () => { this.checkValidation("file_data") });//2DO Debug CORS raising error
    }

    handle_pdf = (UploadFile, UploadFileBase64) => {
        if (globals.parameters.debugging)
            console.log("UploadFile:", JSON.stringify(UploadFile))
        this.setState({
            did_not_change: false,
            file_data: UploadFileBase64,
            full_file_name: UploadFile ? UploadFile.name : ""
        }, () => { this.checkValidation("file_data") }, console.log("state with pdf=>", this.state));//2DO Debug CORS raising error
    }

    //Validation methods
    errorClass = (error) => {
        return (error.length === 0 ? '' : 'has-error');
    }

    checkValidation = (fieldName) => {
        let value = null;
        try {
            value = this.state[fieldName];
        }
        catch (e) {
            alert(`Field '${fieldName}' not found`)
        }

        if (globals.parameters.debugging)
            console.log("fieldName:", fieldName, " value:", value)

        let fieldValidationErrors = this.state.formErrors;

        switch (fieldName) {
            case 'file_data':
                fieldValidationErrors.file_data = (value.length > 0) ? '' : ' est obligatoire';
                break;

            case 'full_file_name':
                fieldValidationErrors.full_file_name = (value.length > 1) ? '' : ' est obligatoire';
                break;

            case 'title':
                fieldValidationErrors.title = (value.length > 1) ? '' : ' est obligatoire';
                break;

            case 'time_stamp':
                fieldValidationErrors.time_stamp = (value.length > 0) ? '' : ' est obligatoire';
                break;

            case 'description':
                fieldValidationErrors.description = (value.length > "<p><br></p>".length) ? '' : ' est trop court';
                break;

            default:
                alert(`checkValidation field' ${fieldName}' not found !`)
                break;
        }

        if (globals.parameters.debugging) {
            console.clear()
            console.log(`-----------------`)
            console.log(`fieldName => ${fieldName}`)
            console.log(`value => ${value}`)
            console.log(`fieldValidationErrors => ${fieldValidationErrors}`)
            console.log(`fieldValidationErrors.join('').length => ${Object.values(fieldValidationErrors).join("").length}`)
        }

        //update internal state
        this.setState({ formErrors: fieldValidationErrors }, () => { if (globals.parameters.debugging) console.log("formErrors =", this.state.formErrors) });

        return (Object.values(fieldValidationErrors).join("").length === 0);
    }

    onCheckFormValidationBeforeSubmit = () => {
        //Force all fields check
        try {
            let canSubmit = this.checkValidation('file_data')
            canSubmit &= this.checkValidation('title')
            canSubmit &= this.checkValidation('full_file_name')
            //Date no more required canSubmit &= this.checkValidation('time_stamp')
            canSubmit &= this.checkValidation('description')

            return (canSubmit);
        }
        catch (e) {
            return false;
        }
    }

    render = () => {
        return (
            <div id="media-form-container">
                <ModalBody>
                    <form id="media-form" onSubmit={this.props.data ? this.onFormUpdateHandler : this.onFormSubmitHandler}>
                        <div className="form-group row">
                            <div className="col-md-6 hidden">
                                <label className="col-md-12 col-form-label">Nom du fichier d'origine:</label>
                                <div className="col-md-10">
                                    <input
                                        readOnly="true"
                                        name="full_file_name"
                                        className="form-control"
                                        value={this.state.full_file_name}
                                        onChange={(e) => { this.handle_full_file_name(e); }}
                                        type="text" placeholder="Nom du média avec extension ie:fasciule.pdf" />

                                    {/* Sticky error label */}
                                    <label
                                        className="form-error">
                                        {this.state.formErrors.full_file_name}
                                    </label>
                                </div>

                            </div>

                            <div className="col-md-12">
                                <label className="col-md-12 col-form-label">Données du média:</label>
                                <div className="col-md-10">
                                    <Blob3
                                        class="pic"
                                        Caption="Charger le média ..."
                                        data={this.state.file_data}
                                        uploadPicture={this.handle_picture}
                                        uploadPdfFile={this.handle_pdf}
                                        buttonStyle={"btn btn-secondary"}
                                    />

                                    {/* Sticky error label */}
                                    <label
                                        className="form-error">
                                        {this.state.formErrors.file_data}
                                    </label>
                                </div>
                            </div>
                        </div>


                        <div className="form-group row hidden">
                            <div className="col-md-6">
                                <label className="col-md-12 col-form-label">Date:</label>
                                <div className="col-md-10">
                                    <input name="time_stamp" className="form-control" value={this.state.time_stamp} onChange={(e) => { this.handle_time_stamp(e); }} type="date" placeholder="Date de l'évènement" />

                                    {/* Sticky error label */}
                                    <label
                                        className="form-error">
                                        {this.state.formErrors.time_stamp}
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label className="col-md-12 col-form-label">Titre:</label>
                                <div className="col-md-10">
                                    <input name="title" className="form-control" value={this.state.title} onChange={(e) => { this.handle_title(e); }} type="text" placeholder="Titre de l'évènement" />

                                    {/* Sticky error label */}
                                    <label
                                        className="form-error">
                                        {this.state.formErrors.title}
                                    </label>
                                </div>
                            </div>
                        </div>


                        <div className="form-group row hidden">
                            <div className="col-md-6">
                                <label className="col-md-12 col-form-label">Catégories affectées à ce média :</label>
                                <div className="col-md-12">
                                    <Multiselect
                                        showArrow
                                        options={this.state.availableCategories}
                                        isObject={false}
                                        displayValue="key"
                                        selectedValues={this.state.selectedCategories}
                                        placeholder="Ajouter une catégorie"
                                        emptyRecordMsg=""
                                        onSelect={(selectedList, selectedItem) => { this.setState({ categories: selectedList.join(";") }); }}
                                        onRemove={(selectedList, selectedItem) => { this.setState({ categories: selectedList.join(";") }); }}
                                        disable={false}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <label className="col-md-12 col-form-label">Média activé:</label>
                                <div className="col-md-12">
                                    <input
                                        className='pointer'
                                        ref={this.enabledCheckBoxRef}
                                        id="is_enabled"
                                        type="checkbox"
                                        checked={parseInt(this.state.is_enabled) === 1 ? true : false}
                                        onChange={(e) => { this.onChangeCheckboxEnabled(e) }} />

                                    <label className='pointer' onClick={(e) => { this.onClickCheckboxEnabled(e); }}>Media activé</label>
                                </div>
                            </div>
                        </div>

                        <div className="form-group row hidden">
                            <div className="col-md-12">
                                <label className="col-md-12 col-form-label">Description de l'évènement:</label>
                                <div className="col-md-12 editor-container">

                                    {/* Sticky error label */}
                                    <label
                                        className="form-error">
                                        {this.state.formErrors.description}
                                    </label>
                                    <Editor
                                        value={this.state.description}
                                        onChange={(value) => { this.handle_description_rich_edit(value) }}
                                        readOnly={false}
                                        style={{ height: "150px", margin: "0em", flex: "1" }}
                                    />
                                </div>
                            </div>
                        </div>

                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        form="media-form"
                    >{this.props.data ? "Enregistrer les modifications" : "Soumettre le média"}</Button>

                    <Button className='hidden'
                        color="secondary"
                        onClick={(e) => { this.clearForm(); }}
                        hidden={this.state.did_not_change}>{this.props.data ? "Annuler les modifications" : "Tout effacer"}
                    </Button>

                    {this.props.onClose &&
                        <Button
                            color="secondary"
                            hidden={!this.state.did_not_change}
                            onClick={(e) => { this.props.onClose(); }}>Fermer</Button>}
                </ModalFooter>
            </div>
        )
    }
}

export default FileFormDataSync;