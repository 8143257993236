import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import MailOutline from '@material-ui/icons/MailOutline'
import {globals} from '../Globals/Globals'

class ModalConfirmEnvoiSuivis extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sent:0,
      unsent:0,
      written:0,
      unwritten:0
    };
  }

  componentDidMount = async () => {
    //Call micro-service
    this.mailer_service_db_url = globals.parameters.local?'http://localhost:5100'
                            :'https://mailer.jesusestlareponsepourtous.org';
    this.survey_service_db_url = globals.parameters.local?'http://localhost:4006'
                            :'https://survey-service.jesusestlareponsepourtous.org';
  }

 inc = fieldname => {
      let nextState = this.state;
      nextState[fieldname] ++
      this.setState(nextState)
  } 

  onSuiviFormSubmitHandler = async event => {
    event.preventDefault();

    //Build request id's list 
    console.clear();
    console.log("onPublicationFormSubmitHandler => data", this.props.data)
    
    console.log("----- doPostSuivi ------")

    const doPostSuiviPromise = (mailing_data) => {
      //call mailer-service
      return axios.post(
          `${this.mailer_service_db_url}/mailing-suivis`,
            mailing_data)
    }

    const doPushHistoriqueSuiviPromise = (config_data_stringyfied) => {
      let idrequetes_post_object
      try{
        //retrieve
        idrequetes_post_object = JSON.parse(config_data_stringyfied);
      }
      catch(err){
        console.error(`error in doPushHistoriqueSuiviPromise:${err}`)
      }
      //call jlrpt-service
      console.log(`idrequetes_post_object = ${idrequetes_post_object} from doPushHistoriqueSuiviPromise`)
      return axios.put(
          `${this.survey_service_db_url}/assign-a-request-to-suivi`,
          idrequetes_post_object)
    }

    //Map promises
    const mailing_promises_list = Object.values(this.props.data).map(
      mailing => {
        return (
            doPostSuiviPromise(mailing)
          )
      }
    );

    //Run promises
    Promise.allSettled(mailing_promises_list).then(
      promisesResults =>{
        console.log("Mailing Promises resolved:", promisesResults)

        //map successfull mailed promises
        let mailed_promises_list = []

        promisesResults.forEach(
          mailingPromiseResult => {
            console.log("mailingPromiseStatus:", mailingPromiseResult)
            if (
                (mailingPromiseResult.status==="fulfilled")
                && (mailingPromiseResult.value.data.state === true)){
              this.inc("sent")
              mailed_promises_list.push(doPushHistoriqueSuiviPromise(mailingPromiseResult.value.config.data))
            }
            else{
              this.inc("unsent")
            }
          }
        )

        //Write down success request onto historique_suivi
        console.log("mailed_promises_list about to be pushed =>", mailed_promises_list)
        if (mailed_promises_list){
          //Mails has been successfully sent, let us try to push relevants requetes into historique_suivis table
          Promise.allSettled(mailed_promises_list).then(
            promisesResults =>{
              console.log("Promises resolved:", promisesResults)

              //map successfull mailed promises
              promisesResults.map(
                pushedPromiseResult => {
                  console.log("pushedPromiseStatus:", pushedPromiseResult)
                  if (
                      (pushedPromiseResult.status==="fulfilled")
                      && (pushedPromiseResult.value.data.state === true)){
                    this.inc("written")
                  }
                  else{
                    this.inc("unwritten")
                  }
                }
              )
            }).catch(
              err => {
                console.log("Mailing Promises rejected:", err)
                alert("Vérifiez votre connexion Wifi : " + err);
              }
            )
        } else
        {
          //None request has been mailed
          console.error("None request sent")
        }
      }
    ).catch(
      err => {
        console.log("Mailing Promises rejected:", err)
        alert("Vérifiez votre connexion Wifi : " + err);
      }
    )
  }

  formDisabled = () => {
      return (false);
  }

  render() {
    {/*const externalCloseBtn = <button className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.props.toggle}>&times;</button>;*/}
    return (
      <div>
        <MailOutline className="CursorPointer" onClick={this.toggle}/>
        <Modal isOpen={this.props.modal} toggle={this.props.toggle} className={this.props.className}>
          <ModalHeader>Confirmation d'envoi d'un suivi</ModalHeader>
          <ModalBody>
                <form id="suivi-form" onSubmit={this.onSuiviFormSubmitHandler}> 
                    <h3><b>{this.props.data?this.props.data.length:"Aucune"}</b> requête(s) sélectionnée(s)</h3>
                    <div className="form-group row">
                      <div className="col-sm-10">
                          <q className="col-sm-2 col-form-label">Cette fonctionnalité envoie un courriel a chaque emetteur des requêtes envoyées afin de lui donner la possibilité de nous informer de leur état d'exhaucement. Attention a ne pas en abuser afin d'éviter de polluer la boite mail ou de se faire black-lister par notre hébergeur.</q>
                      </div>
                    </div>
                </form>

                {(this.props.data && (this.state.sent > 0 || this.state.unsent > 0)) &&
                <div>
                  <hr/>
                  <div> 
                    <strong>Rapport d'emission de suivis</strong>
                    {(this.state.sent > 0) &&
                    <div><b>{this.state.sent}</b> e-mail(s) envoyé(s) sur <b>{this.props.data.length}</b> requête(s) sélectionnée(s)</div>}
                    {(this.state.unsent > 0) &&
                    <div><b style={{color:"red"}}>{this.state.unsent}</b> e-mail(s) en <b style={{color:"red"}}>échec</b> sur <b>{this.props.data.length}</b> requête(s) sélectionnée(s)</div>}
                  </div>

                  {(this.state.sent > 0) &&
                  <div>
                    <hr></hr>
                    <strong>Rapport d'inscription en base de données</strong>
                    {(this.state.written > 0) &&
                    <div><b>{this.state.written}</b> suivi(s) inscrits(s) sur <b>{this.state.sent}</b> e-mail(s) envoyé(s)</div>}
                    {(this.state.unwritten > 0) &&
                    <div><b style={{color:"red"}}>{this.state.unwritten}</b> suivi(s) <b style={{color:"red"}}>non inscrits</b> sur <b>{this.state.sent}</b> e-mail(s) envoyé(s)</div>}
                  </div>}
                </div>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" form="suivi-form" disabled={this.formDisabled()}>Poster le suivi</Button>
            <Button color="secondary" onClick={() => {this.props.toggle();}}>Fermer</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalConfirmEnvoiSuivis;