import React, {Component} from "react";
import {Table, Input, Button, Label, DropdownMenu, DropdownItem, ButtonDropdown, DropdownToggle} from "reactstrap";
import "./StatsTable.css";
import axios from 'axios';
import {globals} from '../Globals/Globals'
/*
// get our fontawesome imports
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
*/

class StatsTable extends Component{
    constructor(props){
        super(props);
        this.state={stats_list:null};
    }

    componentDidMount = async () => {
        //Call micro-service
        let service_db_url = globals.parameters.local?'http://localhost:4006/req-stats'
                                :'https://survey-service.jesusestlareponsepourtous.org/req-stats';

        //Let us fetch stats from remote database
        let stats = await axios.get(service_db_url);
        this.setState({stats_list : stats.data.items});
    }

    tableRendering = () => {
        let table_body = null;
        if (!((this.state.stats_list != null) && (this.state.stats_list.length > 0))){
            table_body = <tr><td colSpan="4">Aucun indicateur statistique</td></tr>;
        }
        else{
            table_body = this.state.stats_list.map((statsItem,i)=>{
                return  <tr key={i} className="thumbnail">
                        <td className="col_desc"><p>{statsItem.variable}</p></td>  
                        <td className="col_desc"><p>{statsItem.cumul}</p></td>  
                    </tr>});
                }
        
        return(
            <Table bordered striped>
                <thead>
                    <tr>
                        <th>Indicateurs</th>
                        <th>Nombre de requêtes</th>
                   </tr>
                </thead>
                <tbody>
                    {table_body}
                </tbody>
            </Table>
            );
    }

    render(){
        return (this.tableRendering());
    }
}

export default StatsTable;
