import React, {Component} from "react";
import {Table, Input, Button, Label, DropdownMenu, DropdownItem, ButtonDropdown, DropdownToggle} from "reactstrap";
import axios from 'axios';
import {globals} from "../Globals/Globals"
import Blob3 from "../Blob3/Blob3";

class FilesRawTable extends Component{
    constructor(props){
        super(props);
        this.state={file_list:null};
    }

    componentDidMount = async () => {
        //Let us fetch files from remote database       
        let fileListURL = `${globals.file_list_endpoint.url}${this.props.email_filter? "?email_filter=" + this.props.email_filter:""}`;
        console.log("fileListURL:",fileListURL)
        let files = await axios.get(fileListURL);
        this.setState({file_list : files.data.items});
    }

    onEditFileClickHandler = async (p_int_index_in_array) => {        
        //Retrieve selected file id from selection index array
        let selected_idfile = this.state.file_list[p_int_index_in_array].idfile;

        //Broadcast edit mode to parent view
        if (this.props.onEditMedia)
                this.props.onEditMedia(selected_idfile)
    }

    onOpenFileClickHandler = async (p_int_index_in_array) => {        
        //Returns selected file object

        //Trigger Open viewer parent view
        if (this.props.onOpenMedia)
                this.props.onOpenMedia(this.state.file_list[p_int_index_in_array])
    }

    onDeleteFileClickHandler = async (p_int_index_in_array) => {
        let selected_file = this.state.file_list[p_int_index_in_array]

        if (window.confirm(`Supprimer le fichier "${selected_file.full_file_name}" ?`)){

            //Delete file record from remote Database
            //call requete-service
            var serviceResult = null
            try{
                await fetch(
                        `${globals.file_delete_endpoint.url}`,{
                            headers: {
                                        'Content-Type': 'application/json'},
                            method: "DELETE", 
                            body: JSON.stringify({idfile:selected_file.idfile})
                        }).then(
                    results => {return results.text();}
                    ).then(
                    (server_response) =>{
                        console.log("sever_response =>", server_response)
                        serviceResult = JSON.parse(server_response);
                        //------------------------------------------ Asynchronous promise result handle
                        //Get service aknowledge before resetting state values.
                        console.log("file-delete => returns : ", serviceResult);

                        if (serviceResult.state === true) {
                            //Delete file from state
                            let next_file_list = this.state.file_list;
                            next_file_list = next_file_list.slice(0, p_int_index_in_array).concat(next_file_list.slice(p_int_index_in_array+1, next_file_list.length))
                
                            this.setState({file_list:next_file_list})
                            alert("Média supprimé.")
                        }
                        else{
                            alert("Erreur:" + serviceResult.message);
                        }
                        //--------------------------------------- End of promise Accept
                    } 
                    )
            } catch(err){
                alert(`Verifiez votre connexion wifi : ${err}`);
                return;
            }
        }
    }

    fileTableRendering = () => {
        let table_body = null;

        if (!((this.state.file_list != null) && (this.state.file_list.length > 0))){
            table_body = <tr><td colSpan="4">Aucun Fichier</td></tr>;
        }
        else{
            table_body = this.state.file_list.map((fileItem,i)=>{
                return  <tr key={i} className="thumbnail">
                        {/*<td className="col_logo"><FontAwesomeIcon icon={faEllipsisH} onClick={()=>{this.onDeletefile(parseInt(i,10))}} style={{cursor:"pointer"}}/></td>*/}
                        <td className="col_logo"><Button onClick={()=>{this.onDeleteFileClickHandler(parseInt(i,10))}} style={{cursor:"pointer"}}>Effacer</Button></td>
                        <td className="col_logo"><Button onClick={()=>{this.onEditFileClickHandler(parseInt(i,10))}} style={{cursor:"pointer"}}>Modifier</Button></td>
                        <td className="col_desc"><p>{fileItem.title}</p></td>  
                        <td className="col_desc"><p>{fileItem.full_file_name}</p></td>  
                        <td className="col_logo">
                                <Blob3
                                    readOnly={true}
                                    class="pic"
                                    Caption="Charger le média ..." 
                                    data={fileItem.file_data}/>
                        </td>
                        <td className="col_desc"><p>{fileItem.time_stamp}</p></td>  
                        <td className="col_desc"><p>{fileItem.is_enabled=="1"?"Affiché":"Masqué"}</p></td>  
                    </tr>});
                }

        return(
            <Table striped responsive={true}>
                <thead>
                    <tr>
                        <th>Effacer</th>
                        <th>Modifier</th>
                        <th>Titre</th>
                        <th>fichier</th>
                        <th>Preview</th>
                        <th>Date</th>
                        <th>Visible</th>
                    </tr>
                </thead>
                <tbody>
                    {table_body}
                </tbody>
            </Table>
            );
    }

    fileGridRendering = () => {
        if (((this.state.file_list != null) && (this.state.file_list.length > 0) && (this.props.readOnly && this.props.readOnly == true) ))
        return(
            <div className="events-columns-container">
                {
                    this.state.file_list.map((fileItem,i)=>{
                        return  <div key={i} className="event-column" onClick={()=>{this.onOpenFileClickHandler(parseInt(i,10))}}>
                                <p>{fileItem.title}</p>
                                        <Blob3
                                            readOnly={true}
                                            class="pic"
                                            Caption="Charger le média ..." 
                                            data={fileItem.file_data}/>
                                
                                <p>{fileItem.time_stamp}</p>
                            </div>})
                }
            </div>);
    }

    render(){
        return (
            <>
                {(this.props.readOnly && this.props.readOnly==true) && <>{this.fileGridRendering()}</>}
                {(!this.props.readOnly) && <>{this.fileTableRendering()}</>}
            </>);
    }
}

export default FilesRawTable;
