import React, {Component} from "react";
import './Blob3.css';
import { Document, Page,pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class Blob3 extends Component{
    constructor(props){
        super(props);
        this.state={};
    }

    //-----------------------------------------------------------------------------------------------------------------------------------------
    /*
    La méthode FileReader.readAsDataURL() lit le contenu d'un blob (Blob) ou d'un fichier (File). 
    Lorsque l'opération de lecture est terminée, la propriété result (en-US) contient les données 
    sous la forme d'une chaîne de caractères encodée en base64.
    data:[<mediatype>][;base64],<data>
    */
    readImage = async (aPictureFile) => {
        let _reader = new FileReader();

        _reader.onload = (e) =>{
            //Evènement déclenché à la fin de la lecture du blob et conversion en base 64
            //Create dynamic img DOM
            var image = document.createElement('img');

            //Event trigger as image is loaded into img DOM
            image.onload = (e) => {
                let imageSource = (e.path && e.path[0]) || e.srcElement;//Safari compliancy
                console.log("reduceImage::e = ",e);
                var canvas = document.createElement('canvas'),
                context,
                maxWidth = 800,
                maxHeight = 800,
                width = imageSource.width,
                height = imageSource.height;
        
                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                context = canvas.getContext('2d');
                context.drawImage(imageSource, 0, 0, width, height);
        
                let canvasData = canvas.toDataURL('image/jpg', 0.6);//Jpeg quality reduced from 0.8 down to 0.6
                if (this.props.uploadPicture){
                    if (this.props.removebase64){
                        //remove base64 prefix if property is set
                        this.props.uploadPicture(
                                aPictureFile,
                                {data:canvasData?canvasData.substring("data:image/png;base64".length):"", item_id:this.props.item_id});
                    }
                    else{
                        //Keep base 64 prefix as it is
                        this.props.uploadPicture(
                                aPictureFile, 
                                {data:canvasData?canvasData:"", item_id:this.props.item_id});
                    }
                }
                else{
                    alert("props.uploadPicture not defined")
                }        
            }
    
            image.src = e.currentTarget.result;    
        } 
        _reader.readAsDataURL(aPictureFile);//Déclenchement de la conversion en Base 64
    }

    //-----------------------------------------------------------------------------------------------------------------------------------------
    readPdf = async (aPdfFile) => {
        let _reader = new FileReader();

        _reader.onload = (e) => {
            //console.log("base 64 accomplished with", e.currentTarget.result);
            if (this.props.uploadPdfFile)
                this.props.uploadPdfFile(aPdfFile, e.currentTarget.result)    
        };

        _reader.readAsDataURL(aPdfFile);
        /*
        
        if (this.props.uploadPdfFile)
                this.props.uploadPdfFile(aPdfFile)*/
    }
    
    //-----------------------------------------------------------------------------------------------------------------------------------------
    resetUpload = () => {
        this.props.uploadPicture(null, {data:"",item_id:this.props.item_id});
    }

    //-----------------------------------------------------------------------------------------------------------------------------------------
    onInputChange = (e) => {
        const errs = [] 
        const files = Array.from(e.target.files)
        const types = ['image/png', 'image/jpeg', 'application/pdf']
        const _1Mo = 1024 * 1024
        const SizeLimit = 9

        console.clear()
        //Reset input cache value -  to assure trigger if user select the same file again
        e.target.value = null
        console.log("onInputChange")
        if (files.length > 1) {
          const msg = 'Pas plus d\'une image à la fois'
          alert(msg);
          return;
        }
    
        files.forEach((file, i) => {
            let errCount = errs.length
          if (types.every(type => file.type !== type)) {
            errs.push(`'${file.type}' : format non supporté`)
          }

          if (file.size > (SizeLimit * _1Mo)) {
            errs.push(`'${file.name}' image trop volumineuse (max:${SizeLimit}Méga-octets)`)
          }
          
          console.log(`errCount = ${errCount} vs errs.length = ${errs.length}`)
          if (errCount == errs.length){ //None new error occurs
            if (file.type == "application/pdf"){
                //Read content as PDF
                this.readPdf(file);    
            }
            else{
                //Read content as classical picture
                this.readImage(file);    
            }
          }
        })
    
        if (errs.length) {
          return errs.forEach(err => alert(err))
        }
    }

    //-----------------------------------------------------------------------------------------------------------------------------------------
    isPdfData = () => {
        if (!this.props) return false;
        if (!this.props.data) return false;
        return (this.props.data.substring(1,30).indexOf("pdf") > 0)
    }

    render(){
        return(
            <div>
                {(!this.props.data) &&
                <label id="upload-picture-label" className="btn btn-primary">{this.props.Caption}
                    <input id="nested-input" type="file" accept="image/*,application/pdf" onChange={this.onInputChange} multiple/>
                </label>}

                {(this.props.data && this.props.data.length > 0 && !this.isPdfData()) && 
                    <div class="show-image">
                        <img src={(this.props.removebase64)?"data:image/png;base64":"" + this.props.data} className={this.props.class}/>
                        {/*<input class="update" type="button" value="Update" />
                        <input class="delete" type="button" value="Effacer" />*/}
                        {!this.props.readOnly &&
                        <input className="btn btn-primary delete" type="button" value="Effacer" onClick={this.resetUpload}/>}
                    </div>
                }

                {(this.props.data &&  this.props.data.length > 0 && this.isPdfData()) && 
                    <div class="show-image">
                        <div className={this.props.class}>
                            <Document file={this.props.data} onLoadSuccess={()=>{console.log("Pdf document loaded from Blob3")}}>
                                <Page pageNumber={this.props.pdfPageNumber?this.props.pdfPageNumber:1} />
                            </Document>
                        </div>
                        {!this.props.readOnly &&
                        <input className="btn btn-primary delete" type="button" value="Effacer" onClick={this.resetUpload}/>}
                    </div>
                }
            </div>);
    }
}

export default Blob3;