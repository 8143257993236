/*
BookletsPage component
*/

import React from 'react';
import axios from 'axios';
import { forwardRef } from 'react';
import MaterialTable from 'material-table';
//import styled from 'styled-components';
import ModalConfirmBookletPublication from '../ModalConfirmBookletPublication/ModalConfirmBookletPublication';
import ModalBookletContent from '../ModalBookletContent/ModalBookletContent'

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {globals} from '../Globals/Globals'

import './BookletsPage.css'

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

class BookletsPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {requests:[], selected_subject:null, openPublication:false, filter_rubrique_id: -1, filter_etat_id:-1};
        this.materialTableRef = React.createRef();
    }

    componentDidMount = () => {
        this.service_db_url = globals.parameters.local?'http://localhost:4002'
                                :`https://envois.jesusestlareponsepourtous.org`;
    }

    refreshMaterialTable = () => {
        if (this.materialTableRef.current) 
          this.materialTableRef.current.onQueryChange()
        else
          console.error("refreshMaterialTable failed in BookletsPage.js")
    }

    onConfirmBookletPublication = async (p_int_idenvois) =>{
      try{
        console.log("Publication du livret confirmée", p_int_idenvois);

        //call requete-service
        let url = `${this.service_db_url}/publish-booklet`;
        const serviceResult = await axios.put(
              url,
              {"idenvois":p_int_idenvois});
  
        //Get service aknowledge before resetting state values.
        console.log("publish-booklet returns data : ", serviceResult.data);
  
        if (serviceResult.data.state === true) {
          //Refresh local table
          this.refreshMaterialTable();
        }else{
          alert("Une erreur est survenue :" + serviceResult.data.message);
        }
      }catch(err){
        alert("Vérifiez votre connexion Wifi :" + err);
      }
    }

    RemoteData = () => {
        return (
          <div>
          <MaterialTable
            tableRef={this.materialTableRef}
            icons={tableIcons}
            title="Livrets."
            actions={[
                rowData => ({
                    icon: () => <ModalBookletContent 
                                  titre_envoi = {rowData.titre_envoi}
                                  idenvois = {rowData.idenvois}/>,
                    tooltip: 'Copier/coller le contenu du livret',
                })
              ]}
              
            options={{
                selection: false,
                search: false,
                grouping: false,
                showTitle: false,
                sorting:false,
                paging:true
                //actionsColumnIndex: -1
              }}
              
            columns={[
              {
                title: 'Livret',
                field: 'titre_envoi'
              },

              { 
                title: 'Statut', 
                field: 'date_publication_livret',
                render: rowData => (
                  //<span className="btn btn-primary" onClick={()=>AskBookletPublication}>En cours...</span>
                  <ModalConfirmBookletPublication 
                    onConfirm={this.onConfirmBookletPublication} 
                    titre_envoi={rowData.titre_envoi}
                    date_publication_livret = {rowData.date_publication_livret}
                    idenvois={rowData.idenvois}/>
                ),
              },
            ]}

            data={query =>
              new Promise((resolve, reject) => {
                let url = `${this.service_db_url}/booklet-list-page`;

                //-- pagination support
                url += '?size=' + query.pageSize; //Set number of records by page
                url += '&offset=' + (query.page) * query.pageSize //Compute first record offset for requested page
                
                console.log("filter_url:",url)

                fetch(url)
                  .then(response => response.json())
                  .then(result => {
                    resolve({
                      data: result.items,
                      page: query.page,//Current page
                      totalCount: result.items.length>0?result.items[0].total_count:0,
                    })
                  })
              })
            }
          />
          </div>
        )
      }
      
    render(){
            return (<div>{this.RemoteData()}</div>);
    }
}

export default BookletsPage;