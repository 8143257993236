/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from 'react';
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MaterialTable from 'material-table';
import ModalConfirmDeletePublication from '../ModalConfirmDeletePublication/ModalConfirmDeletePublication'
import {globals} from '../Globals/Globals'

class ModalHistoriqueEnvois extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      envois:[], 
      modal: false,
      sujet_revise : this.props.rowData.sujet_revise,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount = () => {
    this.materialTableRef = React.createRef();
    this.requetes_service_db_url = globals.parameters.local?'http://localhost:4001'
                            :`https://requetes.jesusestlareponsepourtous.org`;

    this.envois_service_db_url = globals.parameters.local?'http://localhost:4002'
                            :`https://envois.jesusestlareponsepourtous.org`;
  }

  fetchTotalEnvois = async () => {
      const fetchresult = await axios.get(`${this.requetes_service_db_url}/requete-histo-count?idrequetes=${this.props.rowData.idrequetes}`);
      this.setState({envois : fetchresult.data.items});
  };

  onConfirmDeleteRequestFromBooklet = async (p_int_idhistorique_envois) =>{
    try{
      console.log("Suppression confirmée", p_int_idhistorique_envois);
      //this.props.deleteActionHandler(itemId);
      //call requete-service
      let url = `${this.envois_service_db_url}/env-remove-from-blk?idhistorique_envois=${p_int_idhistorique_envois}`;
      const serviceResult = await axios.delete(url,{"idhistorique_envois":p_int_idhistorique_envois});

      //Get service aknowledge before resetting state values.
      console.log("client_add_requetes_log returns data : ", serviceResult.data);

      if (serviceResult.data.state === true) {
        //Refresh child local table
        if (this.materialTableRef.current)
          this.materialTableRef.current.onQueryChange();
        console.log("Requête retranchée du livret");
        //Refresh main retranscription table
        if (this.props.refreshHandler)
          this.props.refreshHandler();
      }else{
        alert("Une erreur est survenue :" + serviceResult.data.message);
      }
    }catch(err){
      alert("Vérifiez votre connexion Wifi :" + err);
    }
  }

  toggle() {
    this.fetchTotalEnvois();//2DO Debug
    this.setState(prevState => ({
      modal: !prevState.modal, sujet_revise : this.props.rowData.sujet_revise
    }), ()=>{if (this.state.modal == true) {this.fetchTotalEnvois();} });
  }

  envois_token = (p_int_nb_envois, p_bool_click_enabled) => {
    //const color_level = ["white","yellow","orange","purple","green","blue","black","red"];
    const color_level = [
      {backgroundColor:"white", color:"black"},
      {backgroundColor:"yellow", color:"black"},
      {backgroundColor:"orange", color:"blue"},
      {backgroundColor:"purple", color:"white"},
      {backgroundColor:"green", color:"white"},
      {backgroundColor:"blue", color:"white"},
      {backgroundColor:"black", color:"white"},
      {backgroundColor:"red", color:"white"}
    ];
    
    //limit color level
    let current_color_level = color_level[color_level.length - 1].backgroundColor;//Set max color by default
    let current_font_color_level = color_level[color_level.length - 1].color;//Set max color by default

    if (p_int_nb_envois < color_level.length){
      //Return available color level
      current_color_level = color_level[p_int_nb_envois].backgroundColor;
      current_font_color_level = color_level[p_int_nb_envois].color;
    }

    if (p_bool_click_enabled)
    return(
          <span
            style={{ height: 36,
                width: 36,
                borderColor:'blue',
                borderStyle:'solid',
                borderWidth:'3px',
                backgroundColor: current_color_level,
                borderRadius: '50%',
                display:'inline-block',
                cursor:'pointer',
                textAlign:'center',
                color:current_font_color_level,
                padding:'4px'
            }}
          onClick={this.toggle} hint="Envois" title="Envois">{p_int_nb_envois}</span>
    )
    else
    return(
          <span
            style={{ height: 36,
                width: 36,
                borderColor:'lightgray',
                borderStyle:'solid',
                borderWidth:'4px',
                backgroundColor: current_color_level,
                borderRadius: '50%',
                display:'inline-block',
                cursor:'default',
                textAlign:'center',
                color:current_font_color_level,
                padding:'4px'
            }}>{p_int_nb_envois}</span>
    )
  }

  requetesStatusRenderer = rowData => {
    let requete_status
    
    if (!rowData.titre_envoi){
      //This is a requte state
      requete_status = `${rowData.titre_etat}`
    } 
    else{
      requete_status = 
        (rowData.date_publication_livret)?
                        `Publiée le ${rowData.date_publication_livret}`:
                        `Affectée à : ${rowData.titre_envoi}`
    }

    return requete_status
  }

  render() {
    const externalCloseBtn = <button className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.toggle}>&times;</button>;
    return (
      <div>
        {this.envois_token(this.props.rowData.nb_envois, true)}

        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} external={externalCloseBtn}>
          <ModalHeader>Historique de la requête</ModalHeader>
          <ModalBody>
          <div className="form-group row">
                <label className="col-sm-2 col-form-label">Requête:</label>
                <div className="col-sm-10">
                    <textarea name="sujet_revise" className="form-control" value={this.state.sujet_revise} type="text" readonly="true"/>
                </div>
          </div>
          <MaterialTable
                tableRef={this.materialTableRef}
                icons={this.props.tableIcons}
                
                options ={{
                  grouping: false,
                  search: false,
                  showTitle: false,
                  sorting:false
                }}

                columns={[
                  {
                    title: 'Supprimer',
                    field: 'idhistorique_envois',
                    hint: 'Retirer de la publication',
                    onClick: () => {alert("idhistorique_envois cliqué")},
                    render: rowData => (
                      <ModalConfirmDeletePublication 
                          onConfirm={this.onConfirmDeleteRequestFromBooklet}
                          date_publication_livret={rowData.date_publication_livret} 
                          idhistorique_envois={rowData.idhistorique_envois}/>
                      ),
                  },
                  {
                    title: 'envois',
                    field: 'nb_envois',
                    render: rowData => (
                        this.envois_token(rowData.nb_envois, false)
                      ),
                  },
                  { title: 'Date', field: 'time_stamp' },
                  { 
                    title: 'Etat requête', 
                    render : rowData => (this.requetesStatusRenderer(rowData))
                     
                  },
                ]}

                data={query =>
                  new Promise((resolve, reject) => {
                    let url = `${this.requetes_service_db_url}/req-hst-page?idrequetes=${this.props.rowData.idrequetes}`;

                    //-- pagination support
                    url += '&size=' + query.pageSize; //Set number of records by page
                    url += '&offset=' + (query.page) * query.pageSize //Compute first record offset for requested page
                    
                    fetch(url)
                      .then(response => response.json())
                      .then(result => {
                        resolve({
                          data: result.items,
                          page: query.page,//Current page
                          totalCount: this.state.envois.length,
                        })
                      })
                  })
                }
            />

          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={()=>{this.toggle()}}>Ok</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalHistoriqueEnvois;