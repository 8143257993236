const production = true;
const local = !production;

/* Prototype 	: cGUID
* Purpose 		: Generates a Global Unique ID
* Return		  :	Return GUID
* History   	: Created on 2015-02-06
*
**/
const cGUID = () => {
    let d = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
};

  /***
 * Prototype 	: 	cTimeStamp
 * Purpose 		: 	return TimeStamp as "AAAA-MM-JJ HHMMSS" format
 * History		: 	Created on 2014-08-06
 *
 **/
const cTimeStamp = () => {
    let lDate = new Date();
    let l_sTimeStamp = '';

    l_sTimeStamp = 1900 + lDate.getYear();
    l_sTimeStamp += '-';
    l_sTimeStamp += ('0' + (lDate.getMonth() + 1)).slice(-2);
    l_sTimeStamp += '-';
    l_sTimeStamp += ('0' + lDate.getDate()).slice(-2);
    l_sTimeStamp += ' ';

    l_sTimeStamp += ('0' + lDate.getHours()).slice(-2);
    l_sTimeStamp += ('0' + lDate.getMinutes()).slice(-2);
    l_sTimeStamp += ('0' + lDate.getSeconds()).slice(-2);

    return (l_sTimeStamp);
}


const globals = {
    parameters : {
            debugging : false, 
            local:local
        },
    menu : {
            donate : "Soutenir la mission",
            visit_us : "Visiter la croisade de la prière",
            daily_worship : "Pensée du jour",
            booklets: "Fascicules",
            about_us:"A propos de nous..."
    },
    file_list_endpoint : {
        ...(local && {url : 'http://localhost:4009/file-list'}),
        ...(production && {url : 'https://media-service.lacroisadedelapriere.com/file-list'})
    },
    file_add_endpoint : {
        ...(local && {url : 'http://localhost:4009/file-add'}),
        ...(production && {url : 'https://media-service.lacroisadedelapriere.com/file-add'})
    },
    file_update_endpoint : {
        ...(local && {url : 'http://localhost:4009/file-update'}),
        ...(production && {url : 'https://media-service.lacroisadedelapriere.com/file-update'})
    },
    file_load_endpoint : {
        ...(local && {url : 'http://localhost:4009/file-load'}),
        ...(production && {url : 'https://media-service.lacroisadedelapriere.com/file-load'})
    },
    file_delete_endpoint : {
        ...(local && {url : 'http://localhost:4009/file-delete'}),
        ...(production && {url : 'https://media-service.lacroisadedelapriere.com/file-delete'})
    },
    datasync :{
        /*DATASYNC*/
        jlrpt_company_guid:"3",
        media_table_guid:"3",
       
        //http://localhost/flutter_sync/php/Sync.php?action=syncStart&F={%22session_guid%22:%22ff1d1f3c-3791-4dc9-9d4b-6048e8de44b3%22,%22company_guid%22:1,%22date_synchro%22:%221970-07-10%20063050%22}
        
        /* MacBook-Air local
        syncPushUrl : "http://localhost:8888/flutter_sync/php/Sync.php",
        syncStartUrl: "http://localhost:8888/flutter_sync/php/Sync.php?action=syncStart",
        syncPullUrl : "http://localhost:8888/flutter_sync/php/Sync.php?action=syncPull",*/

        /*  ASUS local */
        ...(local && {
        syncTestUrl : "http://localhost/datasync-service/test.php",
        syncPushUrl : "http://localhost/datasync-service/Sync.php",
        syncStartUrl: "http://localhost/datasync-service/Sync.php?action=syncStart",
        syncPullUrl : "http://localhost/datasync-service/Sync.php?action=syncPull",
        syncMobilePullUrl : "http://localhost/datasync-service/Sync.php?action=syncMobilePull"}),
        
        
        /* Neuronal.bible Production Server */
        ...(production && {
        syncTestUrl : "http://www.neuronal.bible/datasync-service2/test.php",
        syncPushUrl : "https://www.neuronal.bible/datasync-service2/Sync.php",
        syncStartUrl: "https://www.neuronal.bible/datasync-service2/Sync.php?action=syncStart",
        syncPullUrl : "https://www.neuronal.bible/datasync-service2/Sync.php?action=syncPull",
        syncMobilePullUrl : "https://www.neuronal.bible/datasync-service2/Sync.php?action=syncMobilePull"}),
        /**/
    }
}

export {globals, cGUID, cTimeStamp}