import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import './ModalConfirmBookletPublication.css'

class ModalConfirmBookletPublication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    const externalCloseBtn = <button className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.toggle}>&times;</button>;
    return (
      <div>
        {this.props.date_publication_livret === null &&
          <span className="btn btn-primary" onClick={this.toggle}>En cours...</span>
        }
        {this.props.date_publication_livret !== null &&
          <span> publié le {this.props.date_publication_livret}</span>
        }
        {/*{this.props.idenvois != -1 &&
        <DeleteOutline className="CursorPointer" onClick={this.toggle}/>}*/}

        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} external={externalCloseBtn}>
          <ModalHeader>Confirmation de publication</ModalHeader>
          <ModalBody>
            <b>{this.props.titre_envoi}</b>
            <br/>
            <br/>
            <q>Toute publication est irréversible et ne peut ce faire qu'une seule fois par livret !</q>
            
            <p><br/>Voulez-vous publier ce livret ?</p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={()=>{this.props.onConfirm(this.props.idenvois);this.toggle()}}>Publier</Button>
            <Button color="secondary" onClick={this.toggle}>Annuler</Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalConfirmBookletPublication;