import React from 'react';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],

    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],

    ["clean"],
  ],
};

function Editor(props) {

  return (
      <ReactQuill
        //theme="snow"
        value={props.value}
        onChange={(value) => {if (props.onChange) props.onChange(value);}}
        modules={modules}
        style={props.style}
        readOnly={props.readOnly}
      />
  );
}

export default Editor;