/**
 * ModalConfirmRestoreRequete Component
 */

import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import {globals} from '../Globals/Globals'

class ModalConfirmRestoreRequete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      restored:false
    };
  }
  
  componentDidMount = () => {
    this.service_db_url = globals.parameters.local?'http://localhost:4001/req-restore-from-archive'
                            :`https://requetes.jesusestlareponsepourtous.org/req-restore-from-archive`;
  }

  onRestoreClickHandler = async event => {
    event.preventDefault();

    //Build request id's list 
    console.log("onArchivingClickHandler => data", this.props.data)
    const idrequetes_list = Object.values(this.props.data).map(
      requete => {
          return (
              `${requete.idrequetes}`
          )
      }
    );

    try{
        //call requete-service
        const serviceResult = await axios.put(
          this.service_db_url,
            {idrequetes_list : idrequetes_list.join(",")});

        //Get service aknowledge before resetting state values.
        if (serviceResult.data.state === true){
            console.log("requête restaurées : result.data.message => ", serviceResult.data.message);
            
            //Display back full requetes paragraph in text area
            this.setState({restored:true}, ()=>{console.log("state after restore", this.state);});
        }
        else{
            alert("Erreur:" + serviceResult.data.message);
        }
    } catch(err){
      alert("Vérifiez votre connexion Wifi : " + err);
    }
  }

  render = () => {
    return (
      <div>
        <RestoreFromTrashIcon className="CursorPointer" onClick={this.toggle}/>
        {/*<SaveAlt onClick={() => {alert("Toggle twice !"); this.props.toggle();}}/>
        <SaveAlt/>*/}
        <Modal isOpen={this.props.modal} toggle={this.props.toggle} className={this.props.className}>{/* External props.toggle in order to support material table global action button */}
          <ModalHeader>Rétablissement de requêtes</ModalHeader>
          
          <ModalBody>
                <q>
                  Cette opération récupère des archives la ou les requetes sélectionnées.<br/>Ces requêtes seront à nouveau visibles depuis la console de retranscritpion.
                </q>

                {this.state.restored && <div><br/><strong>Requête(s) restaurée(s)</strong></div>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onRestoreClickHandler} hidden={this.state.restored}>Restaurer</Button>
            <Button color="secondary" onClick={() => {this.props.toggle();}} >Fermer</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalConfirmRestoreRequete;